
import { environment } from './../../environments/environment';

export const API_PATH = {
  user: {
    login: environment.baseAPI + '/login',
    providers: environment.baseAPI + '/login/providers',
    cognito: environment.baseAPI + '/login/cognito',
    azure: environment.baseAPI + '/login/azure',
    resetPassword: environment.baseAPI + '/users/{email}/reset-password',
    resetPasswordRequest: environment.baseAPI + '/users/$email/reset-password-request?redirectUri=' + environment.redirectUri,
  },
  self: {
    userInfo: environment.baseAPI + '/self',
    userOperations: environment.baseAPI + '/self/operations',
    protectedAccessToken: environment.baseAPI + '/self/protected-access-token'
  },
  entities: {
    serviceCentres: environment.baseAPI + '/service-centers',
    serviceCentresAdmin: environment.baseAPI + '/admin/service-centers',
    createServiceCentresAdmin: environment.baseAPI + '/admin/service-centers/create',
    bays: environment.baseAPI + '/service-centers/{serviceCenterId}/bays',
    serviceCentresBeacons: environment.baseAPI + '/service-centers/beacons',
    serviceCentresBeacon: environment.baseAPI + '/service-centers/beacons/{beaconId}',
    beacons: environment.baseAPI + '/beacons',
    list: environment.baseAPI + '/entities/?includes=tree',
    entities: environment.baseAPI + '/entities',
    serviceCentreDetails: environment.baseAPI + '/service-centers/$serviceCenterId',
    publics: environment.baseAPI + '/entities/publics',
    getBays: environment.baseAPI + '/service-centers/bays',
    getAllSuburbAdmin: environment.baseAPI + '/admin/service-centers/all-suburb',
  },
  bookings: {
    bookings: environment.baseAPI + '/bookings',
    expressJobs: environment.baseAPI + '/bookings/express-services/jobs',
    logbookJobs: environment.baseAPI + '/bookings/logbook-services/jobs',
    bookingDetails: environment.baseAPI + '/bookings/{bookingId}',
    bookingDetailsWithUuid: environment.baseAPI + '/bookings/uuid/{bookingUuid}',
    bookingActivities: environment.baseAPI + '/bookings/{bookingId}/activities',
    deleteBookingActivities: environment.baseAPI + '/bookings/{bookingId}/payments/{bookingPaymentId}/',
    extraOptions: environment.baseAPI + '/bookings/{bookingId}/extra-options',
    bookingsSummaries: environment.baseAPI + '/bookings/summaries',
    bookingStatus: environment.baseAPI + '/bookings/{bookingId}/statuses',
    bookingStatusCheckin: environment.baseAPI + '/bookings/{bookingId}/statuses/checked-in',
    bookingPayment: environment.baseAPI + '/bookings/{bookingId}/payments',
    timeslots: environment.baseAPI + '/bookings/timeslots',
    createExpressBooking: environment.baseAPI + '/admin/bookings/express-services',
    createLogbookBooking: environment.baseAPI + '/admin/bookings/logbook-services',
    cancelBooking: environment.baseAPI + '/bookings/{bookingId}/statuses/cancelled',
    pickUp: environment.baseAPI + '/bookings/{bookingId}/pickup/',
    beverages: environment.baseAPI + '/bookings/beverages',
    bookingBeverages: environment.baseAPI + '/bookings/{bookingId}/beverages',
    downloadReceipts: environment.baseAPI + '/admin/bookings/{bookingId}/receipts/',
    serviceTypes: environment.baseAPI + '/bookings/servicetypes',
    adminTimeSlots: environment.baseAPI + '/admin/bookings/timeslots',
    updateAppointmentRef: environment.baseAPI + '/bookings/{bookingId}/appointment',
    updateBookingAdminNote: environment.baseAPI + '/bookings/{bookingId}/admin-note',
  },
  customers: {
    customers: environment.baseAPI + '/customers',
  },
  geos: {
    states: environment.baseAPI + '/geos/states',
    suburbs: environment.baseAPI + '/geos/suburbs'
  },
  publics: {
    extraOptions: environment.baseAPI + '/extra-options',
    beverages: environment.baseAPI + '/beverages',
    imageHandler: environment.baseAPI + '/settings/instances/entities/public/image-handler'
  },
  setting: {
    serviceSchedules: environment.baseAPI + '/settings/service-schedules',
    brandConfig: `${environment.baseAPI}/settings/instances/entities/public/brand-configs`,
    editBrandConfig: `${environment.baseAPI}/settings/instances/entities/brand-configs`,
  },
  vehicles: {
    customers: environment.baseAPI + '/vehicles/customers',
    conditions: environment.baseAPI + '/vehicles/{vehicleId}/conditions?bookingId={bookingId}',
    condition: environment.baseAPI + '/vehicles/{vehicleId}/conditions/{vehicleConditionId}',
    conditionTypes: environment.baseAPI + '/types/VehicleConditionType',
    positionTypes: environment.baseAPI + '/types/VehiclePositionType',
    searchRego: environment.baseAPI + '/vehicles',
    makes: environment.baseAPI + '/vehicles/makes',
    models: environment.baseAPI + '/vehicles/models',
    variants: environment.baseAPI + '/vehicles/variants',
    years: environment.baseAPI + '/vehicles/years'
  },
  admin: {
    vehicles: environment.baseAPI + '/admin/vehicles',
    vehicleUpdate: environment.baseAPI + '/admin/vehicles/{vehicleId}',
    customerCreate: environment.baseAPI + '/admin/users/customers',
    createVehicleForCustomer: environment.baseAPI + '/admin/vehicles',
    serviceCentreDetails: environment.baseAPI + '/admin/service-centers/{serviceCentreId}',
    serviceCentersExport: environment.baseAPI + '/admin/service-centers/export',
  },
  payment: {
    cyberSourceSignature: environment.baseAPI + '/admin/payments/cybersource-signature',
    meta: environment.baseAPI + '/payments/express-services/meta',
    status: environment.baseAPI + '/bookings/{bookingId}/payment-statuses'
  },
  navigatorpro: {
    meta: environment.baseAPI + '/navigatorpro/meta',
    callback: environment.baseAPI + '/navigatorpro/punchout-callback/'
  },
  types: {
    bookingStatus: environment.baseAPI + '/types/BookingStatus',
    bookingProgress: environment.baseAPI + '/types/BookingProgress'
  },
  jobs: {
    addCustomJob: environment.baseAPI + '/bookings/{bookingId}/jobs/',
    editOrDeleteCustomJob: environment.baseAPI + '/bookings/{bookingId}/jobs/{bookingJobId}'
  },
  flightDecks: {
    list: environment.baseAPI + '/flight-decks',
    tracking: environment.baseAPI + '/flight-decks/trackings',
  },
  rolesPermissions: {
    roles: environment.baseAPI + '/rbac/roles/',
    updateRole: environment.baseAPI + '/rbac/roles/{roleCode}',
    permissionsOfRoles: environment.baseAPI + '/rbac/role/?roleCode={roleCode}',
    updatePermissionsOfRoles: environment.baseAPI + '/rbac/role',
    permissions: environment.baseAPI + '/permissions',
    userRoles: environment.baseAPI + '/users/{userId}/roles/',
    addDeletePermissionsToRole: environment.baseAPI + '/roles/{roleCode}/permissions/{permissionCode}/',
    rights: environment.baseAPI + '/rights',
    rbacList: environment.baseAPI + '/rbac/list',
  },
  staff: {
    staffList: environment.baseAPI + '/staffs',
    editStaff: environment.baseAPI + '/service-centers/staffs/{staffId}/',
    staffRoles: environment.baseAPI + '/users/{userId}/roles/',
    handlePermissionOperation: environment.baseAPI + '/users/{userId}/rights/',
    employeePermissions: environment.baseAPI + '/users/{userId}/permissions/?includes=operations&roleCode={roleCode}',
    setRole: environment.baseAPI + '/users/{userId}/roles/{roleCode}/',
    addEmployee: environment.baseAPI + '/service-centers/staffs/',
    serviceCenterStaffs: environment.baseAPI + '/service-centers/staffs',
    listByPod: environment.baseAPI + '/service-centers/staffs?podId={podId}',
  },
  serviceRecommendations: {
    list: environment.baseAPI + '/service-recommendations/?customerId={customerId}',
    updateDeleteRecommendation: environment.baseAPI + '/service-recommendations/{serviceRecommendationId}',
    addRecommendation: environment.baseAPI + '/service-recommendations/'
  },
  companies: {
    list: environment.baseAPI + '/companies',
    edit: environment.baseAPI + '/companies/{companyId}',
    export: environment.baseAPI + '/companies/export',
  },
  expressServices: {
    list: environment.baseAPI + '/service-centers/express-services',
    add: environment.baseAPI + '/service-centers/express-services',
    edit: environment.baseAPI + '/service-centers/express-services/{expressJobId}'
  },
  bays: {
    add: environment.baseAPI + '/service-centers/bays',
    list: environment.baseAPI + '/admin/service-centers/bays',
    edit: environment.baseAPI + '/service-centers/bays/{bayId}',
    serviceType: environment.baseAPI + '/types/ServiceType',
  },
  pods: {
    list: environment.baseAPI + '/service-centers/pods',
    add: environment.baseAPI + '/service-centers/pods',
    edit: environment.baseAPI + '/service-centers/pods/{podId}',
    assignToBay: environment.baseAPI + '/service-centers/pods',
    addStaffs: environment.baseAPI + '/service-centers/pods/{podId}/staffs',
    removeStaffs: environment.baseAPI + '/service-centers/pods/{podId}/staffs',
  },
  beacons: {
    list: environment.baseAPI + '/admin/service-centers/beacons',
    add: environment.baseAPI + '/service-centers/beacons',
    edit: environment.baseAPI + '/service-centers/beacons/{beaconId}'
  },
  gateways: {
    list: environment.baseAPI + '/service-centers/gateways',
    add: environment.baseAPI + '/service-centers/gateways',
    edit: environment.baseAPI + '/service-centers/gateways/{gatewayId}',
    assignToBay: environment.baseAPI + '/service-centers/gateways'
  },
  specialOffers: {
    list: environment.baseAPI + '/service-centers/additional-options',
    add: environment.baseAPI + '/service-centers/additional-options',
    edit: environment.baseAPI + '/service-centers/additional-options/{additionalOptionId}'
  },
  globalSpecialOffer: {
    list: environment.baseAPI + '/companies/additional-options',
    add: environment.baseAPI + '/companies/additional-options',
    edit: environment.baseAPI + '/companies/additional-options/{additionalOptionId}'
  },
  integrating: {
    list: environment.baseAPI + '/navigatorpro/settings/',
    edit: environment.baseAPI + '/navigatorpro/settings/'
  },
  oemMatch: environment.baseAPI + '/service-centers/{serviceCenterId}/brands?vehiclemake={make}',
  flightDeckForBeacons: environment.baseAPI + '/service-centers/beacons/statuses',
  kpiReports: {
    bookingSummary: environment.baseAPI + '/kpi-reports/summaries/booking',
    bookingTypesSummary: environment.baseAPI + '/kpi-reports/summaries/booking-types',
    revenueSummary: environment.baseAPI + '/kpi-reports/summaries/total-revenue',
    trendLeadsSummary: environment.baseAPI + '/kpi-reports/summaries/trend-leads',
    listAdditionOptions: environment.baseAPI + '/kpi-reports/additional-options',
    listbrandServiceOptions: environment.baseAPI + '/kpi-reports/brand-services',
    serviceCenterServices : environment.baseAPI + '/kpi-reports/servicecenter-services',
    stateServices: environment.baseAPI + '/kpi-reports/state-services',
  },
  warrantyClaimInfo: {
    claimTypes: environment.baseAPI + '/claims/claim-types',
    problemClassification: environment.baseAPI + '/claims/problem-classifications',
    resuceValue: environment.baseAPI + '/claims/rescues',
    sundryCodeInfo: environment.baseAPI + '/claims/sundry',
    operationCodeInfo: environment.baseAPI + '/claims/operation-codes',
    partsAmountInfo: environment.baseAPI + '/claims/parts/',
    operationFaultCodeInfo: environment.baseAPI + '/claims/fault-codes/',
    operationProcessingCodeInfo: environment.baseAPI + '/claims/processing-codes/',
    operationLabourUnitPriceInfo: environment.baseAPI + '/claims/operation-codes/unit-price/',
    saveClaimForm: environment.baseAPI + '/claims',
    // Job order API
    claimStatus: environment.baseAPI + '/claims/claim-status',
    bookingList : environment.baseAPI + '/services/bookings',
    bookingDetailsByIdFromList : environment.baseAPI + '/services/booking-details/',
    vehicleDetailsInfo : environment.baseAPI + '/services/vehicle-info',
    projectCategoryDetailsById : environment.baseAPI + '/job-orders/project-categories/',
    projectSubCategoryDetailsById : environment.baseAPI + '/job-orders/project-sub-categories/',
    sparePartsDetailsInfo: environment.baseAPI + '/job-orders/part-types',
    partsCodeInfo : environment.baseAPI + '/claims/parts',
    allPartsCodeList : environment.baseAPI + '/claims/part-details/',
    claimDetailsById: environment.baseAPI + '/claims/job-orders/',
    jobOrderList: environment.baseAPI + '/claims/job-orders',
    saveJobOrderForm: environment.baseAPI + '/claims/job-orders',
    updateJobOrderForm: environment.baseAPI + '/claims/job-orders',
    updateCreateClaimForm : environment.baseAPI + '/claims',
    deleteJobOrder: environment.baseAPI + '/claims/job-orders',
    deleteClaimDetailsById : environment.baseAPI + '/claims',
    ClaimList: environment.baseAPI + '/claims',
    attachmentUpload: environment.baseAPI + '/claims/upload-attachment',
    reportList : environment.baseAPI + '/reports',
    claimDetailsByIdFromList : environment.baseAPI + '/claims/warranty-claims/',
    submitClaimDetails : environment.baseAPI + '/claims/submit',
    vehicleSeriesById : environment.baseAPI + '/claims/vehicle-series',
    deleteAttchment: environment.baseAPI + '/claims/remove-attachment',
    getS3PreSignature : environment.baseAPI + '/claims/GetPreSignedURL',
    getsettlementList : environment.baseAPI + '/claims/settlement',
    saveClaimAttachmentDetails : environment.baseAPI + '/claims/save-claim-attachments-information'
  }

}
