import { BOOKING_LEAD_DAYS_MIN } from '../constants/validation';

const isRequired = 'is required';
const enterRequest = 'Please enter your';
const isInvalid = 'is invalid';
const refreshAndTryAgain = 'Please refresh the page and try again.';
const alreadyExists = 'already exists.';

export const ERROR_MESSAGES = {
  'firstNameRequired': `First name ${isRequired}`,
  'firstNameInvalid': `First name ${isInvalid}`,
  'lastNameRequired': `Last name ${isRequired}`,
  'lastNameInvalid': `Last name ${isInvalid}`,
  'emailRequired': `Email address ${isRequired}`,
  'homeAddressRequired': `Email address ${isRequired}`,
  'emailInvalid': `Email address ${isInvalid}`,
  'enquiriesEmailInvalid': `General Enquiries Email ${isInvalid}`,
  'duplicateEmail': `The email is already associated with an account`,
  'duplicateSCContactEmail': `Email already exists`,
  'phoneNumberRequired': `Phone number ${isRequired}`,
  'desRequired': `Description ${isRequired}`,
  'priceRequired': `Price ${isRequired}`,
  'phoneNumberInvalid': `Phone number ${isInvalid}`,
  'passwordRequired': `Password ${isRequired}`,
  'passwordsNotMatch': 'Password and confirm password are not matched',
  'loginInfoIncorrect': 'Wrong username/password.',
  'unauthorizedLogin': 'Access denied.',
  'internalIssue': 'Internal Server Error',
  'userHasNoPermissions': 'You have no permissions.',
  'cardNumberRequired': `Card number ${isRequired}`,
  'expiredDateRequired': `Expired date ${isRequired}`,
  'ccvRequired': `CCV ${isRequired}`,
  'cardNameRequired': `Card name ${isRequired}`,
  'paymentAmount': 'Please enter payment amount',
  'cardTypeUndetectable': 'Card type is undetectable',
  'postCodeUndetectable': 'Post code is undetectable',
  'invalidDateCard': `Expired date ${isInvalid}`,
  'generalError': `Problems encountered. ${refreshAndTryAgain}`,
  failQualifying: 'Qualifying was failed. Please try again.',
  checkInErrorMessage: 'Unable to check in if booking status is not "Qualified"',
  roleNameRequired: 'Role Name is required.',
  roleNameExists: `Role name ${alreadyExists}`,
  'entityRequired': `Entity ${isRequired}`,
  'scratchImageEncounteredIssues': `The scratch image has encountered issues. ${refreshAndTryAgain}`,
  'noteRequired': `Note ${isRequired}`,
  'addressRequired': `Address ${isRequired}`,
  'stateRequired': `State ${isRequired}`,
  'postCodeRequired': `Post code ${isRequired}`,
  'suburbRequired': `Suburb ${isRequired}`,
  'smsCodeRequired': `Dealer SMS Code ${isRequired}`,
  'departmentSMSCodeRequired': `Department SMS Code ${isRequired}`,
  'enquiriesEmailRequired': `General Enquiries Email ${isRequired}`,
  'rechargeCodeRequired': `Recharge Code ${isRequired}`,
  'expressServiceIntervalRequired': `Booking interval for express service ${isRequired}`,
  'expressServiceIntervalInvalid': `Booking interval for express service ${isInvalid}`,
  'logbookServiceIntervalRequired': `Booking interval for logbook service ${isRequired}`,
  'logbookServiceIntervalInvalid': `Booking interval for logbook service ${isInvalid}`,
  'exceptionalDateFrom': `Date is required`,
  'exceptionalDateFromInvalid': `Invalid date`,
  'exceptionalDateTo': `Date is required`,
  'exceptionalDateToInvalid': `Invalid date`,
  exceptionalDateName: 'Name of the exceptional date is required',
  exceptionalDateOpen: 'Open time is required',
  exceptionalDateOpenInvalid: 'Open time is invalid',
  exceptionalDateClose: 'Close time is required',
  exceptionalDateCloseInvalid: 'Close time is invalid',
  serviceCentreNameRequired: 'Service centre name is required',
  serviceCentreRequired: `Service centre ${isRequired}`,
  'tableNameRequired': `Table number ${isRequired}`,
  internalErrorOccurred: 'Internal error occurred. Please refresh the page and try again.',
  'jobCodeRequired': `Job Code ${isRequired}`,
  'dmsJobRequired': `DMS Job Code ${isRequired}`,
  'descriptionRequired': `Description ${isRequired}`,
  'priceInvalid': `Price ${isInvalid}`,
  'priceMaxLenght': `Price ${isInvalid}`,
  'cylindersRequired': `Cylinders ${isInvalid}`,
  'bayNameRequired': `Bay name ${isRequired}`,
  'serviceTypeIdRequired': `Service type ${isRequired}`,
  'podIdRequired': `POD ${isRequired}`,
  'gatewayIdRequired': `Gateway ${isRequired}`,
  'podNameRequired': `Pod name ${isRequired}`,
  'beaconRequired': `Beacon code ${isRequired}`,
  'gatewayCodeRequired': `Gateway code ${isRequired}`,
  'deviceIDRequired': `Device ID ${isRequired}`,
  'specialOfferNameRequired': `Special offer name ${isRequired}`,
  'imageRequired': `Image ${isRequired}`,
  'assignStaffInvalid': `Assign staff ${isInvalid}`,
  'removeStaffInvalid': `Remove staff ${isInvalid}`,
  'bookingLeadDaysRequired': `Booking lead days ${isRequired}`,
  'bookingLeadDaysMinValue': `Booking lead days must be more than ${BOOKING_LEAD_DAYS_MIN} day`,
  'usernameRequired': `Username ${isRequired}`,
  'appIdRequired': `App ID ${isRequired}`,
  'apiKeyRequired': `API key ${isRequired}`,
  'siteCodeRequired': `Site code ${isRequired}`,
  'apiVersionRequired': `Api version ${isRequired}`,
  'apiEnpointRequired': `Api endpoint ${isRequired}`,
  'EnpointUriRequired': `Endpoint uri ${isRequired}`,
  premiumDurationRequired: `Premium duration ${isRequired}`,
  premiumPlusDurationRequired: `Premium Plus duration ${isRequired}`,
  tyreBonusDurationRequired: `Tyre Bonus duration ${isRequired}`,
  oemADurationRequired: `A duration ${isRequired}`,
  oemBDurationRequired: `B duration ${isRequired}`,
  oemCDurationRequired: `C duration ${isRequired}`,
  oemDDurationRequired: `D duration ${isRequired}`,
  lastDropOffRequired: `Last drop off time ${isRequired}`,
  lastDropOffInvalid: `Last drop off time ${isInvalid}`,
  minimumPickupRequired: `Minimum pickup time ${isRequired}`,
  minimumPickupInvalid: `Minimum pickup time ${isInvalid}`,
}
